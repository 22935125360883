<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        贵州省放射影像专业质控中心<br/>“基于病例的质控培训”第二期成功举办
      </div>
      <section>
        <div class="div_p">
          2024年3月26日，由贵州省放射影像专业质量控制中心主办，贵州医科大学附属医院承办，贵州省人民医院协办的第二期“基于病例的质控培训”在我院影像楼四楼半会议室如期顺利举行。国家放射影像专业质控中心主任委员金征宇教授、副主任委员薛华丹教授、秘书孙昊教授以及中国医科大学附属盛京医院侯阳教授参会指导，省放射影像专业质控中心主任委员高波教授致欢迎辞，贵州省人民医院影像科马海彦副主任医师汇报病例，省人民医院急诊内科吕霞主任医师、呼吸科韩靖主任医师、影像科王涛副主任医师以及遵义医科大学附属医院影像科李邦国教授、我院影像科谢弘副主任医师参会讨论，来自全省9个市州以及辽宁、新疆、陕西、吉林、西藏等兄弟省市影像同仁1200余人通过线上和线下的方式参会。会议由省放射影像专业质控中心副主任委员王荣品教授与阮志兵教授、我院影像科毛巨江副主任医师、影像学院副院长张燕副主任医师主持。
        </div>
        <div>
          <img src="@/assets/GZSPX/1.png" alt="">
          <img src="@/assets/GZSPX/2.png" alt="">
          <img src="@/assets/GZSPX/3.png" alt="">
          <img src="@/assets/GZSPX/4.png" alt="">
        </div>
        <div class="div_tit">
          全省各地参会情况
        </div>
        <div>
          <img src="@/assets/GZSPX/5.png" alt="">
        </div>
        <div class="div_tit">
          线上参会情况
        </div>
        <div>
          <img src="@/assets/GZSPX/6.png" alt="">
        </div>
        <div class="div_p">
          金征宇主委强调，全国各省市临床与影像医师经验、能力参差不齐，尤其基层工作人员，加强质控、沟通与同质化培训至关重要，希望在国家放射影像专业质控中心和贵州省放射影像专业质控中心的共同努力下，将“基于病例的质控培训”做成规范标准的全流程质控培训模板向全国进行推广，真正达到质控与同质化培训目的，希望参会专家、同道利用好此平台加强学习、沟通交流，不断推动全国放射影像专业质控工作。高波首先感谢国家放射影像专业质控中心专家团队对本次活动的大力支持，感谢省内外影像专家、同道的积极参与，“基于病例的质控培训”活动是新形势下质控与培训工作模式的积极探讨与尝试，希望在国家放射影像专业质控中心及全国影像同仁的指导和帮扶下，逐步将“基于病例的质控培训”做成特色，做成品牌。
        </div>
        <div class="div_p">
          病例汇报环节，马海彦通过对病例的临床特点、影像检查、影像学表现与诊断等方面作了精彩的病例汇报，并对急慢性肺动脉栓塞的影像诊断与鉴别诊断知识点进行了分享。病例讨论环节，谢弘、王涛、李邦国分别针对引起肺动脉栓塞常见及少见的原因、可疑肺动脉栓塞患者影像学检查方法选择、如何提高肺动脉图像质量与降低图像伪影、急性肺动栓塞诊断和危急值上报流程等方面从影像学角度对该病例进行了分析和热烈讨论，吕霞和韩婧分别从自己临床专业方面针对本病危急值处理、影像诊断、非典型急性肺动脉栓塞鉴别、如何提升检查阳性率、哪类肺栓塞需报危急值以及如何通过质控提高同质化水平等方面提出了意见、建议和更高要求。线下、线上嘉宾讨论热烈，富有成效。
        </div>
        <div class="div_p">
          嘉宾点评环节，孙昊指出，实时监控危急值患者影像检查的全过程，以保障危急值检查结果的及时处理和上报，提高对患者的及时救治率；同时建议对急性CTPA检查阳性率应进行追踪统计，加强与临床科室的沟通与交流，以提高临床科室开单的阳性率；强调要不断提升影像技师对危急值的鉴别能力，让危急值的上报流程更加规范。侯阳教授对急性肺动脉栓塞CT平扫和增强影像征象进行了深度的剖析，强调要培养和提高规培医师和低年资医师对危急值的鉴别能力，同时指出通过VTE评分强化临床科室危急值意识，在影像检查的全过程中强化高风险和高危患者的筛查，达到更好把握急性肺栓塞不良预后的效果。薛华丹指出，此次“基于病例的质控培训”是关于危急值一次跨界、跨专业的交流与分享，实现了专业间的破壁与省际间的织网，对于提高影像危急值的认识与鉴别能力起到积极的促进作用；同时指出现阶段影像危急值的上报范围与标准还不统一，为危急值的上报工作带来一定的难度，并对近期国家放射影像专业质控中心有关危急值专题讨论会与国家卫健委医政医管司对于2024年质控工作提出的破壁、织网和向死亡学习的三大工作重心进行了介绍。同时强调如何通过对死亡病例的讨论学习，提升影像质控水平，是未来质控工作中每一个影像从业者需要思考的问题。
        </div>
        <div>
          <img src="@/assets/GZSPX/7.png" alt="">
          <img src="@/assets/GZSPX/8.png" alt="">
          <img src="@/assets/GZSPX/9.png" alt="">
        </div>
        <div class="div_p">
          在专题讲座环节，孙昊以《放射影像危急值和上报流程探讨》为题给线下线上同道作了精彩的专题讲座，让参会人员对放射影像危急值定义、范围和上报流程工作有了进一步了解和认识。
        </div>
        <div class="div_p">
          高波总结表示，在国家放射影像专业质控中心的统一布局和领导下，第二期“基于病例的质控培训”活动圆满成功，实现了科室间、医院间以及省际间跨区域、跨专业同道的质控交流，活动目的是基于临床病例进行影像全流程（临床检查申请，检查前准备，影像检查技术，影像诊断等方面）质控，针对存在的问题/不足进行培训、提升，多学科探讨助力临床个性化精准诊疗。下一步，将梳理“基于病例的质控培训”活动规范与细则，加强影像技术同质化和标准化培训水平，持续提升贵州省医学影像服务能力与水平，助力放射影像专业质控工作高质量发展。
        </div>
        <div>
          <img src="@/assets/GZSPX/10.png" alt="">
        </div>



      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "GZSPX",

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin-bottom: 10px;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>